@import url('../../../assets/styles/styleGlobal.module.css'); 


.main {
  width: 77%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
}

.table {
  padding-top: 20px;
  overflow-y: scroll;
  min-height: 300px;
}