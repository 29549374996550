@import url('../../assets/styles/styleGlobal.module.css'); 

.footer { 
    
    position: fixed;
    bottom: 0px;
    padding-right: 10px ;
    font-size: 13px;
    text-align: right;
    width: 100%;
}

a.link {
    color: var(--color-global-system);
    text-decoration: none;

}

a.link:hover {
    text-decoration: none;
    font-weight: bold;
    color: var(--color-global-system);
}
