@import url('../../assets/styles/styleGlobal.module.css'); 

.bodyOrders {
    background-color: #fff;
    padding: 10px;
}

.headerOrders {
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

a.cardsContents {
    text-decoration: none;
    color: #fff;

    font-size: 18px;
    padding: 8px 0px ;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

a.cardsContents:hover {
    opacity: 0.9;
    color: #fff;
}