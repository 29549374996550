
@import url('../../../../assets/styles/styleGlobal.module.css'); 

.allValues{
    background-color: #f7f6f6;

    display: flex;
    justify-content: space-around;
    align-items: center;

    border-radius: 5px;
    
}

.values {
    text-align: center;
}

.valor_total {
    color: var(--color-primary);
    font-weight: bold;
}

.valor_pagar {
    color: var(--color-success);
    font-weight: bold;
}

.troco {
    color: var(--color-danger);
    font-weight: bold;
}

.formInputs {
    padding: 20px 90px 0 90px;
}

.payments{
    padding-top: 20px;
}

.tableItems thead{
    background-color: var(--color-global-system);
    color: #fff;
}

.tablePayments thead{
    background-color: var(--color-global-system);
    color: #fff;
}

