:root {
    --color-global-system: #417169;
    --color-white: #fff;

    --color-primary: #0d6efd;
    --color-orange: #fd7e14;
    --color-purple: #6f42c1;
    --color-secondary: #6c757d;
    --color-success: #198754;
    --color-cyan: #0dcaf0;
    --color-danger: #dc3545;
    --color-dark: #212529;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body {
    background-color: #f4f6f7;
}