@import url('../../../assets/styles/styleGlobal.module.css'); 

.aside {
  width: 20%;
  background-color: #fff;
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  padding: 10px 0 0 0;
}