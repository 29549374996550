.asideitem{
	display: flex;
	justify-content: space-between;
	padding: 0 15px 0px 15px;
}

.asideitem span {
	margin-bottom: 20px;
}

